/**
 * Created by gschulz on 25.03.15.
 */
var FormSimple = (function () {
    var _private = {};

    _private.instance = this;

    _private.form = $();

    _private.afterInitFunctions = [];

    this.init = function (form) {
        _private.form = form;
        new FormObserver().init(form);
        var formTable = new FormTable();
        formTable.init(form);
        form.find('select').change();
        form.find('input[type=text],textarea').keyup();
        _private.bindEvents();
        _private.initContextMenu();
        _private.initCoreMirrorElements();
        $.each(_private.afterInitFunctions,function (i,afterInitFunction) {
            afterInitFunction.apply(this,[_private.instance])
        });
        return this;
    };

    _private.initCoreMirrorElements = function () {
        $.each(_private.form.find('div.formelement.code_mirror'), function (i, div) {
            var textarea = $(div).find('textarea');
            if (!textarea.length) {
                return;
            }
            var codeMirrorInstance = $(textarea).data('CodeMirrorInstance');
            if (!_.isUndefined(codeMirrorInstance)) {
                codeMirrorInstance.refresh();
                return;
            }
            codeMirrorInstance = CodeMirror.fromTextArea(textarea.get(0), {
                lineNumbers: true,
                mode: "application/json",
                gutters: ["CodeMirror-lint-markers"],
                lint: true
            });
            codeMirrorInstance.setSize(textarea.width());
            codeMirrorInstance.on("change", function (instance) {
                $(instance.getTextArea()).unbind('keyup.validateJson').on('keyup.validateJson',
                    function (event) {
                        Validator.validateJson($(event.target))
                    }
                );
                $(instance.getTextArea()).val(instance.getValue()).keyup();
            });
            $(textarea).data('CodeMirrorInstance', codeMirrorInstance);
        });
    };

    _private.initContextMenu = function () {
        var primaryId = 0;
        var primaryName = '';
        var params = {};
        var primaryElement = _private.form.find('input.primary');
        if (primaryElement.length) {
            primaryId = parseInt(primaryElement.val());
            primaryName = primaryElement.attr('name');
            params[primaryName] = primaryId;
        }
        var contextMenuOptions = {};
        if (parseInt(primaryId) > 0) {
            contextMenuOptions.delete = {
                params: params,
                click: new Abstract().deleteObject,
                clickArgs: [_private.form, 'Object']
            };
            contextMenuOptions.copy = {
                click: new Abstract().copyObject,
                clickArgs: [_private.form, _private.instance.copy]
            };
        }
        ContextMenu.setContentMenu(contextMenuOptions);
        ContextMenu.init();
    };

    _private.bindEvents = function () {
        $('#global-save-button').unbind('click.saveFrom').on('click.saveFrom',
            function () {
                new Abstract().saveForm(_private.form,
                    function(form){_private.instance.init(form)},
                    _private.getPrepareFromData(_private.form)
                );
            }
        );
    };

    this.copy = function (form, response) {
        if (_.isUndefined(response.data)) {
            return false;
        }
        var newForm = $(response.data);
        $(form).replaceWith(newForm);
        var state = urlHelper.getState();
        var primaryElement = _private.form.find('input.primary');
        var primaryName = 'id';
        if (primaryElement.length) {
            primaryName = primaryElement.attr('name');
        }
        state.params[primaryName] = 0;
        urlHelper.setState(state);
        _private.instance.init(newForm);
        return true;
    };

    /**
     *
     * @param {*} form
     * @returns {*}
     */
    _private.getPrepareFromData = function (form) {
        var data = form.serializeObject();
        form.find('div.formelement.textarea.code_mirror').find('textarea').each(function (i, textarea) {
            var name = $(textarea).prop('name');
            if (!name || _.isUndefined(data[name])) {
                return;
            }
            var array = [];
            try {
                array = jQuery.parseJSON(data[name]);
                if (_.isNull(array)) {
                    array = [];
                }
            } catch (e) {
                array = [];
            }
            data[name] = array;
        });
        return data;
    };

    this.setAfterInitFunctions = function (afterInitFunctions) {
        _private.afterInitFunctions = afterInitFunctions;
        return this;
    };

    this.addAfterInitFunction = function (afterInitFunction) {
        _private.afterInitFunctions.push(afterInitFunction);
        return this;
    };

});